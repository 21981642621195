
const addHttps = url => {
	if (!url.startsWith('http://') && !url.startsWith('https://')) {
		return `https://${url}`
	}
	return url
}

export const redirectToUrl = url => {
	window.location.href = addHttps(url)
}


