import fetchApi from '@/utils/fetchApi';
import * as restAPI from '@/utils/endpoints';

const initialState = {
  homePageCards: [],
  status: 'idle',
};

export default {
  state: initialState,
  reducers: {
    RESET() {
      return initialState;
    },
    SET_HOME_PAGE_CARDS(state, payload) {
      return {
        ...state,
        homePageCards: payload,
      };
    },
    SET_STATUS(state, payload) {
      return {
        ...state,
        status: payload,
      };
    },
  },
  effects: (dispatch) => ({
    async REQUEST_HOME_PAGE_CARDS() {
      dispatch.homePageCards.SET_STATUS('loading');
      const response = await fetchApi(restAPI.GET_HOME_PAGE_CARDS);

      if (Array.isArray(response)) {
        dispatch.homePageCards.SET_HOME_PAGE_CARDS(response);
        dispatch.homePageCards.SET_STATUS('success');
      } else {
        console.error('API error:', response.error); 
        dispatch.homePageCards.SET_STATUS('error');
      }
    },
  }),
};