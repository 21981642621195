import Color from 'color'
import { css } from '@emotion/core'
const stops = [100, 80, 60, 20, 5]

const createColorRange = color =>
	stops.map(step => {
		const flip = (100 - step) / 100
		const va = Color(color).mix(Color('white'), flip)
		return {
			key: step === 100 ? '' : `-${step}`,
			hex: va.hex(),
			rgb: va
				.rgb()
				.color.map(item => parseFloat(item.toFixed(2), 10))
				.join(',')
		}
	}, {})

const createClassNames = (theme, styles, name = 'primary') => {
	return theme.reduce((a, current) => {
		return `${a} ${styles.reduce((b, styl) => {
			const [key, prop, variants = []] = styl
			const standard = `.${key}-${name}${current.key} { ${prop}: ${current.hex} };`
			const extras = variants.reduce((acc, variant) => {
				return `${acc} .${variant}\\:${key}-${name}${current.key}:${variant} { ${prop}: ${current.hex} };`
			}, '')

			return `${b} ${standard} ${extras}`
		}, '')}`
	}, '')
}

const createCustomFontCSS = (baseFont, headerFont) =>
{
	let customFontCSS = "";

	if(baseFont)
		customFontCSS += `
			body {
				font-family: ${baseFont};
				font-weight: 400;
			}`

	if(headerFont)
		customFontCSS += `
			.font-h-light {
				font-family: ${headerFont};
				font-weight: 300;
			}
			
			.font-h-bold {
				font-family: ${headerFont}
				font-weight: 700;
			}
			
			h1, h2, h3, h4, h5, h6 {
				font-family: ${headerFont};
				font-weight: 300;
			}`

		return customFontCSS;
}

function createThemeCSS({ primary, accent, baseFont, headerFont }) {
	const props = [
		['text', 'color', ['hover']],
		['border', 'border-color', ['hover', 'focus', 'checked']],
		['bg', 'background-color', ['hover', 'checked']]
	]

	const primaryScale = createColorRange(primary)
	const accentScale = createColorRange(accent)

	const theme = css`
		a:not([class]) {
			color: ${primary};
		}
		${createClassNames(primaryScale, props, 'primary')}
		${createClassNames(accentScale, props, 'accent')}

		.bg-gradient-b-primary {
			background-image: linear-gradient(
				${primaryScale[3].hex} -48.1%,
				${primary}
			);
		}
		
		.checked\\:border-primary:checked + * {
			border-color: ${primary}
		}
		
		.checked\\:bg-accent:checked + * {
			background-color: ${accent}
		}

		.bg-gradient-l-primary {
			background-image: linear-gradient(
				270deg,
				${primaryScale[3].hex} -48.1%,
				${primary}
			);
		}

		.before\\:bg-primary {
			&:before {
				background-color: ${primary};
			}
		}

		.bg-gradient-b-mix {
			background-image: linear-gradient(${primary}, ${accent});
		}

		.shadow-header {
			box-shadow: 0 0.25em 1.1875em ${primaryScale[3].hex};
		}

		.shadow-footer {
			box-shadow: 0 -0.25em 1.1875em ${primaryScale[3].hex};
		}

		#nprogress .bar {
			background-color: ${primary};
		}

		#nprogress .peg {
			color: ${primary};
		}

		input[type='checkbox']:focus + * {
			border-color: ${primaryScale[1].hex};
		}

		.input-focus\\:border-primary-80:focus + * {
			border-color: ${primaryScale[2].hex};
		}
		
		${createCustomFontCSS(baseFont, headerFont)}
		
		@media (min-width: 64em) {
			.lg\\:bg-none {
				background-image: none;
			}
		}
	`

	return theme
}

export default createThemeCSS
