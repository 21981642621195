export const MONTHS = [
	{ isDisabled: false, id: 1, value: 'January' },
	{ isDisabled: false, id: 2, value: 'February' },
	{ isDisabled: false, id: 3, value: 'March' },
	{ isDisabled: false, id: 4, value: 'April' },
	{ isDisabled: false, id: 5, value: 'May' },
	{ isDisabled: false, id: 6, value: 'June' },
	{ isDisabled: false, id: 7, value: 'July' },
	{ isDisabled: false, id: 8, value: 'August' },
	{ isDisabled: false, id: 9, value: 'September' },
	{ isDisabled: false, id: 10, value: 'October' },
	{ isDisabled: false, id: 11, value: 'November' },
	{ isDisabled: false, id: 12, value: 'December' }
]

export const YEARS = [
	{ value: 2009, id: 2009 },
	{ value: 2010, id: 2010 },
	{ value: 2011, id: 2011 },
	{ value: 2012, id: 2012 },
	{ value: 2013, id: 2013 },
	{ value: 2014, id: 2014 },
	{ value: 2015, id: 2015 },
	{ value: 2016, id: 2016 },
	{ value: 2017, id: 2017 },
	{ value: 2018, id: 2018 },
	{ value: 2019, id: 2019 },
	{ value: 2020, id: 2020 }
]

export const datePattern = /([12]\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01]))/

export const MTB_VIEW_MODE = {
	YEAR: 'annually',
	MONTH: 'monthly'
}

export const SORTING_DIRECTIONS = {
	Asc: 'asc',
	Desc: 'desc',
};
