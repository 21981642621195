module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-react-helmet-async/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Bionic-Benefits","short_name":"Bionic-Benefits","start_url":"/","background_color":"#fff","theme_color":"#41748d","display":"standalone","icon":"static/logos/lebc-logo-mobile.svg"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
